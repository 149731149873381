/*
 * @Author: Tperam
 * @Date: 2022-01-18 11:26:26
 * @LastEditTime: 2022-02-18 10:27:48
 * @LastEditors: Tperam
 * @Description: 
 * @FilePath: \pear.link\src\modules\domain\reducer.js
 */
import moment from 'moment'

export const Status = {
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

const checkTime = () => (
  ((moment().unix() - localStorage.getItem('isms_timestamp')) < 6 * 3600 ) ? true : false
)

export default (state = {isAuthenticated: checkTime()}, action) => {
  switch(action.type) {
    case 'DDNS/INFO_STARTED': {
      console.log("start");
        return {...state, fetchInfoStatus: Status.LOADING}
    }
    case 'DDNS/INFO_SUCCESS': {
        console.log("success");
        return {...state, fetchInfoStatus: Status.SUCCESS, info: action.result}
    }
    case 'DDNS/INFO_FAILURE': {
        console.log("failure");
        return {...state, fetchInfoStatus: Status.FAILURE, error: action.error}
    }
    case 'DOMAIN/REGISTERED/LIST/SUBDOMAIN_STARTED': {
        return {...state, getDomainRegisteredListStatus: Status.LOADING}
    }
    case 'DOMAIN/REGISTERED/LIST/SUBDOMAIN_SUCCESS': {
        return {...state, getDomainRegisteredListStatus: Status.SUCCESS, registeredSubdomainList: action.result}
    }
    case 'DOMAIN/CHECKING/SUBDOMAIN_FAILURE': {
        return {...state, getDomainRegisteredListStatus: Status.FAILURE, error: action.error}
    }
    case 'DOMAIN/CHECKING/SUBDOMAIN_STARTED': {
        return {...state, getDomainCheckingStatus: Status.LOADING}
    }
    case 'DOMAIN/CHECKING/SUBDOMAIN_SUCCESS': {
        return {...state, getDomainCheckingStatus: Status.SUCCESS, hihihi: action.result}
    }
    case 'DOMAIN/RECOMMEND/SUBDOMAIN_FAILURE': {
        return {...state, getDomainCheckingStatus: Status.FAILURE, error: action.error}
    }
    case 'DOMAIN/RECOMMEND/SUBDOMAIN_STARTED': {
        return {...state, getDomainRecommendStatus: Status.LOADING}
    }
    case 'DOMAIN/RECOMMEND/SUBDOMAIN_SUCCESS': {
        return {...state, getDomainRecommendStatus: Status.SUCCESS, recommendList:action.result}
    }
    case 'DOMAIN/RECOMMEND/SUBDOMAIN_FAILURE': {
        return {...state, getDomainRecommendStatus: Status.FAILURE, error: action.error}
    }
    case 'DOMAIN/POST_STARTED': {
        return {...state, postPearboxDomain: Status.LOADING}
    }
    case 'DOMAIN/POST_SUCCESS': {
        return {...state, postPearboxDomain: Status.SUCCESS}
    }
    case 'DOMAIN/POST_FAILURE': {
        return {...state, postPearboxDomain: Status.FAILURE, error: action.error}
    }
    default: {
      return state
    }
  }
}
