import xhr from "../../Xhr";


export const messageOk = (dataStr) => ({
    type: 'UTILS/MESSAGE/OK',
    dataStr: dataStr
});
export const messageError = (dataStr) => ({
    type: 'UTILS/MESSAGE/ERROR',
    dataStr: dataStr
});
export const messageWarning = (dataStr) => ({
    type: 'UTILS/MESSAGE/WARNING',
    dataStr: dataStr
});
export const messageInfo = (dataStr) => ({
    type: 'UTILS/MESSAGE/INFO',
    dataStr: dataStr
});
export const setTitle = (dataStr) => ({
    type: 'UTILS/TITLE',
    dataStr: dataStr
});
export const closeMessageOk = () => ({
    type: 'UTILS/MESSAGE/OK/CLOSE'
});
export const closeMessageError = () => ({
    type: 'UTILS/MESSAGE/ERROR/CLOSE'
});
export const closeMessageWarning = () => ({
    type: 'UTILS/MESSAGE/WARNING/CLOSE'
});
export const closeMessageInfo = () => ({
    type: 'UTILS/MESSAGE/INFO/CLOSE'
});
