/*
 * @Author: Tperam
 * @Date: 2022-01-18 11:41:27
 * @LastEditTime: 2022-03-15 15:08:16
 * @LastEditors: Tperam
 * @Description: 
 * @FilePath: \pear.link\src\modules\pearbox\container.js
 */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Box, Tab, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip } from '@material-ui/core';
import {Title} from '../utils/title'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { makeStyles,useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import IconClose from "@material-ui/icons/Close";
import CreateIcon from '@material-ui/icons/Create';
import { Link } from 'react-router-dom';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import DeleteIcon from '@material-ui/icons/Delete';
import { deletePearboxURL, getPearboxDetail, patchPearboxURL, postPearboxURL,setPearboxDetailURLS, setPearboxNickname, updatePearboxNickname } from './actions';
import {messageError, messageOk} from '../utils/actions';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import AutorenewIcon from '@material-ui/icons/Autorenew';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Status } from './reducer';
import { isValidIP } from '../utils/ip_verify';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing.unit * 2,
        overflowX: 'auto',
    },
    main: {
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    title: {
        marginBottom: theme.spacing.unit * 2,
    },
    subTitle: {
        marginTop: theme.spacing.unit * 2,
    },
    textField: {
        // marginLeft: theme.spacing.unit,
        // marginRight: theme.spacing.unit,
        // width: 250,
    },
    button: {
        // marginLeft: theme.spacing.unit,
        // marginRight: theme.spacing.unit,
        // marginTop: theme.spacing.unit,
        // width: 250,
    },
    container: {
        position: "relative",
        width: '100%',
        height: 66,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        background: `#fff`,
        margin: '2px 0',
        cursor: "pointer",
    },
    statusIcon: {
      verticalAlign: "middle",
      fontSize: ".8rem",
      marginRight: theme.spacing(0.5)
    },
    caption: {
      color: theme.myColor.grey,
      lineHeight: 1
    },
    deviceInfoTable:{
        "& tr":{
            "& td":{
                borderBottom:0,
            },
            "& td:first-child":{
                color:"rgba(0,0,0,.4)",
            }
        },
    },
    redirectTable:{
        "& tr":{
            "& td":{
                borderBottom:0,
                fontSize:"0.8rem",
            },
        },
    },
    urlInput:{
        fontSize:"0.8rem",
    }
}));

function MyPearBox(props){
    const {onRequire, pearboxDetail, nickname} = props;
    const classes = useStyles();
    const loadding = false;
    const [editNicknameOpen, setEditNicknameOpen] = useState(false);
    const [nicknameValue, setNicknameValue] = useState(nickname||"")
    useEffect(()=>{
        if ( isNaN(parseInt(props.match.params.id)) ){
            window.location.href="/pearbox/overview"
        }
        onRequire(props.match.params.id)
    },[])

    const handleEditNickname = (event)=>{
        setEditNicknameOpen(false)
        if (nicknameValue !== nickname){
            props.updatePearboxNickname(props.match.params.id,event.target.value)   
        }
    }

    const handleNicknameChange = (e) =>{
        setNicknameValue(e.target.value)
    }

    const domainTableCell = ()=>{
        switch ((pearboxDetail.domains||[]).length){
            case 0 : {
                return <TableCell><Link to={`/pearbox/bind/domain/${props.match.params.id}`}>点击可绑定</Link></TableCell>
            }
            case 1:{
                return <TableCell> {pearboxDetail.domains[0]}
                    {/* <Link to={`/pearbox/bind/domain/${props.match.params.id}`}> 更改</Link> */}
                </TableCell>
            }
            default:{
                return <TableCell>{pearboxDetail.domains[0]}
                    {/* <Link to={`/pearbox/bind/domain/${props.match.params.id}`}> 更改</Link> */}
                </TableCell>
            }
        }
    }

    return (
        <Grid container justifyContent="center" spacing={1}>
            <Grid item xs={12} sm={10} md={9} lg={7} xl={6}>
                <Link to={"/pearbox/overview"}><ArrowBackIosIcon color="primary" /></Link>
                <br />
                <Paper elevation={3} >
                    <Box m={1} padding={2}>
                        {editNicknameOpen ? <TextField color="primary" autoFocus onBlur={handleEditNickname} value = {nicknameValue} onChange={handleNicknameChange}/>:<Typography variant="h5" onClick={()=>{setEditNicknameOpen(true)}} >{nicknameValue || "未命名"}<CreateIcon fontSize="small" /></Typography>}
                        <Typography  variant="subtitle2">设备ID: {pearboxDetail.node_id}</Typography>
                    </Box>
                </Paper>
                <br />
                <Paper elevation={3} >
                    <Box m={1} padding={2}>
                        <Typography variant="h6" gutterBottom>外网配置</Typography> {/* 可以参照 腾讯云 将此处换成Tab */}
                        <Title>本机设置</Title>
                        <Table className={`${classes.deviceInfoTable} mb20`}>
                            <TableRow>
                                <TableCell>访问域名</TableCell>
                                {domainTableCell()}
                            </TableRow>
                            <TableRow>
                                <TableCell>设备ID</TableCell>
                                <TableCell>{pearboxDetail.node_id}</TableCell>
                            </TableRow>
                            {/* <TableRow>
                                <TableCell>当前设备公网IP</TableCell>
                                <TableCell>{pearboxDetail.ip || "无"}</TableCell>
                            </TableRow> */}
                            <TableRow>
                                <TableCell>设备型号</TableCell>
                                <TableCell>{"PearBox 1 Pro"}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>绑定时间</TableCell>
                                <TableCell>{pearboxDetail.created_at ||"空"}</TableCell>
                            </TableRow>
                        </Table>
                        
                        <RedirectTable {...props}/>
                    
                        <Title className={`mt20`}>服务中转</Title>
                    </Box>
                </Paper>
                
            </Grid>
        </Grid>
    )
}

function RedirectTable(props){
    const classes = useStyles()
    const {pearboxDetail} = props;
    const urls = pearboxDetail.urls;

    const rows = (urls||[]).sort((a,b)=>{
        return a.level-b.level
    }).map((v,i)=>{
        return (
            <RedirectRows v={v} i={i} urls={urls} {...props}/>
        )
    })
    return (
        <>
            <Title>已配置跳转IP</Title>
            <Typography  variant="caption" gutterBottom>&emsp;请不要绑定过多跳转路由，这将会影响跳转速度</Typography>
            <Table className={`${classes.redirectTable}`}>
                <TableHead>
                    <TableRow>
                        <TableCell width="30%">可跳转IP</TableCell>
                        <TableCell width="20%">端口</TableCell>
                        <TableCell>优先级</TableCell>
                        <TableCell>操作</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows}
                    <CreateRedirectRows urls={urls} {...props}/>
                </TableBody>
            </Table>
        </>
    )
}

function RedirectRows(props){
    const classes = useStyles()
    const {v,i,urls,patchPearboxURL,movePearboxURL,detailVersion,deletePearboxURL,messageError} = props;

    const [ipValue,setIPValue] = useState(v.ip);
    const [editIPOpen, setEditIPOpen] = useState(false);

    const [portValue,setPortValue] = useState(parseInt(v.port));
    const [editPortOpen, setEditPortOpen] = useState(false);
    
    const [levelValue,setLevelValue] = useState(parseInt(v.level));
    const [editLevelOpen, setEditLevelOpen] = useState(false);
    const [dv, setDV] = useState(detailVersion)
    // if (dv !== detailVersion){
    //     setURLValue(v.url)
    //     setDV(detailVersion)
    // }
    useEffect(()=>{
        setIPValue(v.ip)
        setPortValue(v.port)
        setLevelValue(v.level)
    },[urls])

    const handleEditIP = (e)=>{
        // 调用更新
        let tmpIPValue = (ipValue || "").trim()
        if (v.ip === tmpIPValue){
            setEditIPOpen(false)
            return
        }
        
        if (!isValidIP(tmpIPValue)){
            messageError("不是一个正确的IP")
            return
        }
        
        let tmpURLs = urls.map((val)=>{
            if (val.id === v.id){
                return {...val,ip:tmpIPValue}
            }
            return {...val}
        })
        
        patchPearboxURL([
            {
                ...v,
                "ip":tmpIPValue,
            }
        ],tmpURLs)
        
    
        setEditIPOpen(false)
    }

    const handleEditPort = (e)=>{
        // 调用更新
        let tmpPortValue = parseInt(portValue)
        
        if (v.port === tmpPortValue){
            setEditPortOpen(false)
            return
        }
        if (!(tmpPortValue >= 0 && tmpPortValue <= 65535)){
            messageError("不是一个正确的端口")
            return
        }
        if (isNaN(tmpPortValue)){
            messageError("不是一个正确的端口")
            return
        }
        
        let tmpURLs = urls.map((val)=>{
            if (val.id === v.id){
                return {...val,port:tmpPortValue}
            }
            return {...val}
        })
        
        patchPearboxURL([
            {
                ...v,
                "port":tmpPortValue,
            }
        ],tmpURLs)
        
    
        setEditPortOpen(false)
    }
    const handleEditLevel = (e)=>{
        let tmpLevel = parseInt(levelValue)
        // 调用更新
        if (v.level === tmpLevel){
            setEditLevelOpen(false)
            return
        }
        if (!(tmpLevel >= 0 && tmpLevel <= 255)){
            messageError("优先级必须在 [0-255]")
            return
        }
            
        if (!(urls||[]).every( (v)=>(v.level !== tmpLevel))){
            messageError("优先级不能重复！")
            return
        }
        let tmpURL = urls.map((val)=>{
            if (val.id === v.id){
                return {...val,level:levelValue}
            }
            return {...val}
        })
        patchPearboxURL([
            {
                ...v,
                "level": parseInt(tmpLevel),
            }
        ],tmpURL)
        setEditLevelOpen(false)
    }

    const handleIPValueChange = (e)=>{
        setIPValue(e.target.value)
    }
    const handlePortValueChange = (e)=>{
        setPortValue(e.target.value)
    }
    const handleLevelValueChange = (e)=>{
        setLevelValue(e.target.value)
    }
    const urlFilter = (urls1,urls2)=>{
        return urls1.filter( (v,i) => {
            // 没有修改的则不动
            if (v.id === urls2[i].id &&
                v.level === urls2[i].level &&
                v.ip === urls2[i].ip &&
                v.port === urls2[i].port &&
                v.pearbox_id === urls2[i].pearbox_id &&
                v.created_at === urls2[i].created_at
            ){
                return false
            }
            return true
        })
    }
    // onBlur={handleEditURL}
    const handleMoveUp = (e)=>{
        const sortURL = urls.sort((a,b)=>{
            return a.level-b.level
        })

        let tmpURL = [];
        
        for (let i = 0 ; i < sortURL.length; i ++){
            tmpURL.push({
                ...sortURL[i],
                // level:i,
            })
            
            // id:sortURL[i].id,
            // level: i,
            // url: sortURL[i].url,
            // pearbox_id: sortURL[i].pearbox_id,
            // created_at: sortURL[i].created_at,

            if (tmpURL[i].id === v.id && i > 0){
                let tmp = parseInt(tmpURL[i].level)
                tmpURL[i].level = parseInt(tmpURL[i-1].level)
                tmpURL[i-1].level = tmp
            }
        }

        let updateURL = urlFilter(tmpURL,sortURL)

        movePearboxURL(updateURL,tmpURL)
    }
    const handleMoveDown = (e)=>{
        const sortURL = urls.sort((a,b)=>{
            return a.level-b.level
        })
        
        let tmpURL = [];
        for (let i = 0 ; i < sortURL.length; i ++){
            tmpURL.push({
                ...sortURL[i],
                // level: i,
            })
            if (i > 0 && tmpURL[i-1].id === v.id){
                let tmp = parseInt(tmpURL[i].level)
                tmpURL[i].level = parseInt(tmpURL[i-1].level)
                tmpURL[i-1].level = tmp
            }
        }

        let updateURL = urlFilter(tmpURL,sortURL)
        
        movePearboxURL(updateURL,tmpURL)

    }
    const handleDelete = (e)=>{
        let changedURL = urls.filter( (val)=>{
            return v.id !== val.id
        })
        deletePearboxURL({"id":v.id,"pearbox_id":v.pearbox_id},changedURL)
    }
    const handleBan = (e)=>{
        urls.forEach((val,i)=>{
            if (val.id === v.id){
                urls[i].is_ban ^= 1
            }
        })

        patchPearboxURL([
            {
                ...v
            }
        ],urls)
    }
    const patchLoading = props.patchPearboxURLStatus === Status.LOADING
    return (
        <TableRow key={`${v.id}_${v.level}`}>
            <TableCell>
                {editIPOpen ? 
                    <TextField  className={`${classes.urlInput}`} color="primary" autoFocus onChange={handleIPValueChange} onBlur={handleEditIP} value={ipValue} fullWidth/>:
                    <Typography variant='inherit' onClick={()=>{setEditIPOpen(true)}} style={{display:"inline-block",width:"100%",height:32,lineHeight:"30px"}} >
                        {ipValue} {v.auto_bind !==0?<AutorenewIcon style={{fontSize:"0.8rem"}}/>: null}
                    </Typography>
                }
            </TableCell> 
            <TableCell>
                {editPortOpen ? 
                    <TextField  className={`${classes.urlInput}`} color="primary" autoFocus onChange={handlePortValueChange} onBlur={handleEditPort} value={portValue} fullWidth/>:
                    <Typography variant='inherit' onClick={()=>{setEditPortOpen(true)}} style={{display:"inline-block",width:"100%",height:32,lineHeight:"30px"}} >
                        {portValue}
                    </Typography>
                }
            </TableCell> 
            <TableCell>
                {editLevelOpen ? 
                    <TextField  className={`${classes.urlInput}`} color="primary" autoFocus onChange={handleLevelValueChange} onBlur={handleEditLevel} value={levelValue} fullWidth/>:
                    <Typography variant='inherit' onClick={()=>{setEditLevelOpen(true)}} style={{display:"inline-block",width:"100%",height:32,lineHeight:"30px"}} >
                        {levelValue}
                    </Typography>
                }
            </TableCell>
            
            <TableCell width="25%">
                <IconButton size="small" onClick={handleMoveUp} disabled={i===0 || patchLoading}>
                    <KeyboardArrowUpIcon fontSize="small" htmlColor={i===0? "rgba(0,0,0,.4)":'#6997c5'}/>
                </IconButton>
                <IconButton size="small" onClick={handleMoveDown} disabled={i===urls.length-1||patchLoading}>
                    <KeyboardArrowDownIcon fontSize="small" htmlColor={i===urls.length-1? "rgba(0,0,0,.4)" :'#6997c5'}/>
                </IconButton>
                {v.auto_bind === 0 ?
                <IconButton size="small"onClick={handleDelete} disabled={patchLoading}>
                    <DeleteIcon fontSize="small" htmlColor='rgb(220, 0, 78)'/>
                </IconButton>:
                <IconButton size="small" onClick={handleBan} disabled={patchLoading}> 
                    <Tooltip title={v.is_ban === 1 ? "当前：禁用":"当前：启用"}>
                        <CheckCircleOutlineIcon fontSize="small" htmlColor={v.is_ban === 1 ? 'rgba(0,0,0,.4)': '#50df6f'}/>
                    </Tooltip>
                </IconButton>
                }
            </TableCell>
        </TableRow>
    )
}


function CreateRedirectRows(props){
    const {messageError,postPearboxURL,urls,detailVersion} = props;
    const classes = useStyles()

    const [editURLOpen, setEditURLOpen] = useState(false);

    const [ipValue,setIPValue] = useState("");

    const [portValue,setPortValue] = useState(80);
    

    const [levelValue,setLevelValue] = useState(0);
    const [dv, setDV] = useState(detailVersion)
    if (dv !== detailVersion){
        let maxLevel = 0;
        (urls || []).forEach((v)=>{
            if (v.level > maxLevel){
                maxLevel = v.level
            }
        })
        setLevelValue(maxLevel+1)
        setDV(detailVersion)
    }

    
    
    const handleEditURLOpen = ()=>{
        setEditURLOpen(true)
    }

    const handleIPValueChange = (e) => {
        setIPValue(e.target.value)
    }
    const handlePortValueChange = (e) => {
        setPortValue(e.target.value)
    }
    const handleLevelChange = (e) => {
        setLevelValue(e.target.value)
    }

    const handleSubmit = (e)=>{
        let tmpIPValue = (ipValue || "").trim()
        let tmpPortValue = parseInt(portValue)
        
        console.log(tmpIPValue,tmpPortValue)
        if (!isValidIP(tmpIPValue)){
            messageError("不是一个正确的IP")
            return
        }
        if (!(tmpPortValue >= 0 && tmpPortValue <= 65535)){
            messageError("不是一个正确的端口")
            return
        }
        if (isNaN(tmpPortValue)){
            messageError("不是一个正确的端口")
            return
        }
        let tmpLevel = parseInt(levelValue)
        if (!(tmpLevel >= 0 && tmpLevel <= 255)){
            messageError("优先级必须在 [0-255]")
            return
        }
        
        if (!(urls||[]).every( (v)=>(v.level !== tmpLevel))){
            messageError("优先级不能重复")
            return
        }
        
        postPearboxURL({
            "ip":tmpIPValue,
            "port":parseInt(portValue),
            "level": tmpLevel,
            "pearbox_id": parseInt(props.match.params.id),
        },()=>{
            setEditURLOpen(false)
        })
        
        
    }
    const handleClose = (e)=>{
        setEditURLOpen(false)
    }
    return (
        editURLOpen ? 
        <TableRow className={`${classes.redirectRow}`}>
            <TableCell>
                <TextField color="primary" className={`${classes.urlInput}`} autoFocus onChange={handleIPValueChange}  value={ipValue} fullWidth />
            </TableCell> 
            <TableCell>
                <TextField color="primary" className={`${classes.urlInput}`} autoFocus onChange={handlePortValueChange}  value={portValue} fullWidth />
            </TableCell> 
            <TableCell>
                <TextField color="primary" className={`${classes.urlInput}`} onChange={handleLevelChange}  value={levelValue} />
            </TableCell>
            <TableCell>
                <IconButton size="small">
                    <CheckIcon fontSize="small" onClick={handleSubmit} htmlColor='#50df6f'/>
                </IconButton>
                <IconButton size="small">
                    <CloseIcon fontSize="small" onClick={handleClose} htmlColor='rgb(220, 0, 78)'/>
                </IconButton>
            </TableCell>
        </TableRow>
        : <TableRow ><TableCell colSpan={4} height={20} onClick={handleEditURLOpen} align="center"><AddCircleOutlineIcon fontSize='small' color="primary"/></TableCell></TableRow>
    )
}

const mapStateToProps = (state) => {
    return {
        nickname: state.pearbox.nickname,
        deviceID: state.pearbox.deviceID,
        id: state.pearbox.id,
        pearboxDetail: state.pearbox.pearboxDetail || {},
        detailVersion: state.pearbox.detailVersion,
        patchPearboxURLStatus: state.pearbox.patchPearboxURLStatus,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onRequire:(pearboxID)=>{
            dispatch(getPearboxDetail(pearboxID,{
                success:()=>{},
                error:(err)=>{
                    dispatch(messageError(err.err_info))
                }
            }))
        },
        updatePearboxNickname: (pearboxID,nickname)=>{
            dispatch(setPearboxNickname(nickname))
            dispatch(updatePearboxNickname(pearboxID,nickname))
        },
        patchPearboxURL: (data,changeURL)=>{
            dispatch(patchPearboxURL(data,{
                success:(info)=>{
                    dispatch(messageOk("修改成功！"))
                    dispatch(setPearboxDetailURLS(changeURL))
                },
                error:(err)=>{
                    dispatch(messageError(err.err_info))
                }
            }))
        },
        movePearboxURL: (data,changeURL)=>{
            dispatch(patchPearboxURL(data,{
                success:(info)=>{
                    dispatch(messageOk("修改成功！"))
                    dispatch(setPearboxDetailURLS(changeURL))
                },
                error:(err)=>{
                    dispatch(messageError(err.err_info))
                }
            }))
        },
        postPearboxURL: (data,successFunc)=>{
            dispatch(postPearboxURL(data,{
                success:()=>{
                    dispatch(messageOk("修改成功！"))
                    dispatch(getPearboxDetail(data.pearbox_id,{
                        success:()=>{},
                        error:(err)=>{
                            dispatch(messageError(err.err_info))
                        }
                    }))
                    if (successFunc instanceof Function){
                        successFunc()
                    }
                }
            }))

        },
        deletePearboxURL: (data,changeURL)=>{
            dispatch(deletePearboxURL(data,{
                success:()=>{
                    dispatch(messageOk("修改成功！"))
                    dispatch(setPearboxDetailURLS(changeURL))
                }
            }))
        },
        messageError: (data)=>{
            dispatch(messageError(data))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MyPearBox)