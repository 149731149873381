/*
 * @Author: Tperam
 * @Date: 2022-01-18 11:26:26
 * @LastEditTime: 2022-03-21 11:54:39
 * @LastEditors: Tperam
 * @Description: 
 * @FilePath: \pear.link\src\Routes.js
 */
import React from 'react';
import {Route, BrowserRouter, Switch} from 'react-router-dom';
import Loadable from 'react-loadable'
import PrivateRoute from './PrivateRoute';

const loading = () => (
  <div>Loading...</div>
)

const LoadableHomeLayout = Loadable({
  loader: () => import('./layout/HomeLayout'),
  loading: loading
})

const LoadableNotFound = Loadable({
  loader: () => import('./pages/NotFound'),
  loading: loading
})

const LoadableLogin = Loadable({
  loader: () => import('./pages/Login'),
  loading: loading
})
const LoadableLoginPage = Loadable({
    loader: () => import('./pages/LoginPage'),
    loading: loading
})
const LoadableKuiconnectOverview = Loadable({
    loader: () => import('./pages/KuiconnectOverview'),
    loading: loading
})
const LoadableKuiconnect = Loadable({
    loader: () => import('./pages/Kuiconnect'),
    loading: loading
})

const LoadableDomain = Loadable({
    loader: () => import('./pages/Domain'),
    loading: loading
})

const LoadablePearBox = Loadable({
  loader: () => import('./pages/PearBox'),
  loading: loading
})

const LoadablePearBoxOverview = Loadable({
    loader: () => import('./pages/PearBoxOverview'),
    loading: loading
})
const LoadableWait = Loadable({
    loader: () => import('./pages/Wait'),
    loading: loading
})
const LoadableHome = Loadable({
    loader: () => import('./pages/home'),
    loading: loading
})
const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/login" component={LoadableLogin}></Route>
        {/* <Route path="/login_page" component={LoadableLoginPage}></Route> */}
      <Route path="/" component={LoadableHomeLayout}></Route>
      <Route path="*" component={LoadableNotFound}></Route>
    </Switch>
  </BrowserRouter>
);

const PageRoutes = () => (
  <Switch>
    <PrivateRoute exact path="/pearbox/overview" component={LoadablePearBoxOverview}></PrivateRoute>
    <PrivateRoute exact path="/pearbox/:id" component={LoadablePearBox}></PrivateRoute>
    <PrivateRoute exact path="/pearbox/bind/domain/:id" component={LoadableDomain}></PrivateRoute>
    <PrivateRoute exact path="/sync" component={LoadableWait}></PrivateRoute>
    <PrivateRoute exact path="/wait" component={LoadableWait}></PrivateRoute>
    <PrivateRoute path="/" component={LoadableHome}></PrivateRoute>
    <PrivateRoute path="*" component={LoadableHome}></PrivateRoute>
  </Switch>
);

export {Routes, PageRoutes};