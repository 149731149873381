/*
 * @Author: Tperam
 * @Date: 2022-03-21 16:08:29
 * @LastEditTime: 2022-03-22 11:57:48
 * @LastEditors: Tperam
 * @Description: 
 * @FilePath: \pear.link\src\modules\kuiconnect\deviceTable.js
 */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles,useTheme } from '@material-ui/core/styles';

import {Tab, Table, TableBody, TableCell, TableHead, TableRow, TextField} from '@material-ui/core'

import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing.unit * 2,
        overflowX: 'auto',
    },
    main: {
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    title: {
        marginBottom: theme.spacing.unit * 2,
    },
    subTitle: {
        marginTop: theme.spacing.unit * 2,
    },
    textField: {
        // marginLeft: theme.spacing.unit,
        // marginRight: theme.spacing.unit,
        // width: 250,
    },
    button: {
        // marginLeft: theme.spacing.unit,
        // marginRight: theme.spacing.unit,
        // marginTop: theme.spacing.unit,
        // width: 250,
    },
    container: {
        position: "relative",
        width: '100%',
        height: 66,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        background: `#fff`,
        margin: '2px 0',
        cursor: "pointer",
    },
    statusIcon: {
      verticalAlign: "middle",
      fontSize: ".8rem",
      marginRight: theme.spacing(0.5)
    },
    caption: {
      color: theme.myColor.grey,
      lineHeight: 1
    },
    deviceInfoTable:{
        "& tr":{
            "& td":{
                borderBottom:0,
            },
            "& td:first-child":{
                color:"rgba(0,0,0,.4)",
            }
        },
    },
    redirectTable:{
        "& tr":{
            "& td":{
                borderBottom:0,
                fontSize:"0.8rem",
            },
        },
    },
    urlInput:{
        fontSize:"0.8rem",
    }
}));

export default function DeviceTable(props){
    const classes = useStyles()
    const {pearboxDetail} = props;
    // testData
    const vips=[{
        id:1,node_id:"aabbccdd",name:"hihihi",ip:["10.0.0.1"],lastseen:"online",version:"0.0.1",physical_ip:"113.67.10.61",mac:"00:e8:33:01:66"
    }];

    const rows = (vips||[]).map((v,i)=>{
        return (
            <IPTableRow v={v} i={i} vips={vips} {...props}/>
        )
    })
    return (
        <>
            <Table className={`${classes.redirectTable}`} width="800">
                <TableHead>
                    <TableRow>
                        <TableCell >地址</TableCell>
                        <TableCell>命名描述</TableCell>
                        <TableCell>IP</TableCell>
                        <TableCell>最后登录时间</TableCell>
                        <TableCell>操作</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows}
                </TableBody>
            </Table>
        </>
    )
}

function IPTableRow(props){
    const classes = useStyles()
    const {v,i,vips,messageError} = props;
    if (! (v instanceof Object)){
        return <></>
    }

    return (
        <>
            <TableRow key={`${v.id}_${v.level}`}>
                <TableCell>
                    {v.node_id}
                </TableCell> 
                <TableCell>
                    {v.name}
                </TableCell>
                <TableCell rowSpan={2}>
                    {
                        v.ip.map( val=>(<Typography  variant="subtitle2">{val}</Typography>))
                    }
                </TableCell>
                <TableCell rowSpan={2}>
                    {v.lastseen}
                </TableCell>
                <TableCell rowS pan={2}>
                    <IconButton size="small"onClick={()=>{}}>
                        <DeleteIcon fontSize="small" htmlColor='rgb(220, 0, 78)'/>
                    </IconButton>
                    <IconButton size="small" onClick={()=>{}} > 
                        <CheckCircleOutlineIcon fontSize="small" htmlColor={v.is_ban === 1 ? 'rgba(0,0,0,.4)': '#50df6f'}/>
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow key={`${v.id}_${v.level}`}>
                <TableCell>
                    {v.mac}
                </TableCell> 
                <TableCell>
                    {v.description || `(description)`}
                </TableCell>
            </TableRow>
        </>
    )
}
