/*
 * @Author: Tperam
 * @Date: 2022-01-18 11:41:27
 * @LastEditTime: 2022-03-21 14:36:10
 * @LastEditors: Tperam
 * @Description: 
 * @FilePath: \pear.link\src\modules\kuiconnect_overview\container.js
 */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Tab, TextField, useMediaQuery } from '@material-ui/core';
import {Title} from '../utils/title'
import Tabs from '@material-ui/core/Tabs';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import RightArrowIcon from '@material-ui/icons/NavigateNext';
import DotIcon from '@material-ui/icons/FiberManualRecord';

import { makeStyles,useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {isInternalIP} from '../utils/ip_verify'
import { setTitle} from '../utils/actions'
import { Link } from 'react-router-dom';
import { Status } from './reducer';


const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing.unit * 2,
        overflowX: 'auto',
    },
    main: {
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    title: {
        marginBottom: theme.spacing.unit * 2,
    },
    subTitle: {
        marginTop: theme.spacing.unit * 2,
    },
    textField: {
        // marginLeft: theme.spacing.unit,
        // marginRight: theme.spacing.unit,
        // width: 250,
    },
    button: {
        // marginLeft: theme.spacing.unit,
        // marginRight: theme.spacing.unit,
        // marginTop: theme.spacing.unit,
        // width: 250,
    },
    container: {
        position: "relative",
        width: '100%',
        height: 66,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        background: `#fff`,
        margin: '2px 0',
        cursor: "pointer",
    },
    statusIcon: {
      verticalAlign: "middle",
      fontSize: ".8rem",
      marginRight: theme.spacing(0.5)
    },
    caption: {
      color: theme.myColor.grey,
      lineHeight: 1
    },
}));

function PearboxOverview(props){
    const {pearboxList,onRequire} = props;
    const classes = useStyles();
    const loadding = false;

    useEffect( ()=>{
        onRequire()
    },[])
    
    const [bindDialog, setBindDialog] = useState(false);

    const handleJumpPearboxItem =(nickname,node_id,id)=>{
        return ()=>{
            props.setPearboxNickname(nickname)
            props.setPearboxDeviceID(node_id)
            props.setPearboxID(id)
        }
    }

    return (
        <Grid container justifyContent="center" spacing={1}>
            <Grid item xs={12} sm={10} md={9} lg={7} xl={6}>
                <Title>我的网络</Title>
                <Paper square variant="fullWidth" indicatorColor="primary" textColor="primary">
                    <Tabs value={0} variant="fullWidth" indicatorColor="primary" textColor="primary">
                        <Tab label={"网络"} />
                    </Tabs>
                </Paper>
                {loadding?<LinearProgress color="primary"/>: null }
                {pearboxList.map((v)=>{
                    return <Link key={v.id} to={`/pearbox/${v.id}`} onClick={handleJumpPearboxItem(v.nickname,v.node_id,v.id)}>
                        <PearboxItem nickname={v.nickname} node_id={v.node_id} id={v.id} />
                    </Link>
                })}
                <Link  to={`/kuiconnect/1`} >
                    <PearboxItem networkID={"balabalala"} networkName={"something"} description={"asdasd深圳电信30M asdasdasd"} subnet={"192.168.0.1"} node={{online:1,total:5}} createTime={"2022-03"} />
                </Link>
                
                <div>
                    <a href={`http://pear.link/bind/pearbox?username=${localStorage.getItem("isms_username")}`}>
                        <AddButton title={"创建网络"} subtitle={""}/>
                    </a>
                </div>
            </Grid>
        </Grid>
    )
}
function PearboxItem(props){
    const {networkID,networkName,description,subnet,node,createTime} = props;
    const classes = useStyles();
    return (
        <Grid className={classes.container} container direction={"row"} justify={"space-between"} alignItems={"center"}>
            <Grid item xs container direction={"column"} justify={"center"} alignItems={"flex-start"}>
                <Grid item xs={12}>
                    <Typography variant="subtitle1">{networkName} <Typography variant="caption" className={classes.caption}>{description}</Typography></Typography> 
                </Grid>
                <Grid item xs={12}>
                    <DotIcon className={`${classes.statusIcon}`}/><Typography variant="caption" className={classes.caption}>{networkID}</Typography>
                </Grid>
            </Grid>
            <Grid item>
                <div style={{display: "inline-block", verticalAlign: "top", textAlign: "right"}}>
                    <Typography display="block" variant="caption" className={classes.caption}> {(node || {}).online} / {(node || {}).total}</Typography>
                    <Typography display="block" variant="caption" className={classes.caption}> {subnet} </Typography>
                    {/* <Typography display="block" variant="caption" className={classes.caption}> {createTime} </Typography> */}
                </div>
                <RightArrowIcon className={classes.icon} color="action"/>
            </Grid>
        </Grid>
    )
}

function AddButton(props){
    const  {title,subtitle} = props;
    const classes = useStyles();
    return (
        <Grid className={classes.container} container direction={"row"} justify={"space-between"} alignItems={"center"}>
            <Grid xs container direction={"column"} justify={"center"} alignItems={"center"}>
                <Grid item xs={12} >
                    <Typography variant="subtitle1"  alignItems={"center"}>{title}</Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}


const mapStateToProps = (state) => {
    return {
        pearboxIP: state.pearboxOverview.pearboxIP || "",
        pearboxList: state.pearboxOverview.pearboxList || [],
        probePearboxStatus: state.pearboxOverview.probePearboxStatus,
        probeNum: state.pearboxOverview.probeNum,
        bindMyPearboxStatus: state.pearboxOverview.bindMyPearboxStatus,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onRequire:()=>{
            dispatch(setTitle("PearBox"))
        },

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PearboxOverview)