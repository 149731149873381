import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
//import App from './App';
import {Routes} from './Routes';
import { Provider } from 'react-redux'
import store from './Store.js'
//import Dashboard from './layout/Dashboard';
import * as serviceWorker from './serviceWorker';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider'
import MainTheme from './themes/mainTheme';
import './Style.css'
import SnackBars from "./modules/utils/snackbars";

ReactDOM.render(
  <MuiThemeProvider theme={MainTheme}>
    <Provider store={store}>
        <SnackBars/>
      <Routes />
    </Provider>
  </MuiThemeProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
