/*
 * @Author: Tperam
 * @Date: 2022-01-18 11:41:27
 * @LastEditTime: 2022-03-21 16:11:32
 * @LastEditors: Tperam
 * @Description: 
 * @FilePath: \pear.link\src\modules\kuiconnect\container.js
 */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Box, Tab, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip } from '@material-ui/core';
import {Title} from '../utils/title'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { makeStyles,useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import IconClose from "@material-ui/icons/Close";
import CreateIcon from '@material-ui/icons/Create';
import { Link } from 'react-router-dom';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import DeleteIcon from '@material-ui/icons/Delete';
import { deletePearboxURL, getPearboxDetail, patchPearboxURL, postPearboxURL,setPearboxDetailURLS, setPearboxNickname, updatePearboxNickname } from './actions';
import {messageError, messageOk} from '../utils/actions';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import AutorenewIcon from '@material-ui/icons/Autorenew';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Status } from './reducer';
import { isValidIP } from '../utils/ip_verify';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import DeviceTable from './deviceTable.js'

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing.unit * 2,
        overflowX: 'auto',
    },
    main: {
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    title: {
        marginBottom: theme.spacing.unit * 2,
    },
    subTitle: {
        marginTop: theme.spacing.unit * 2,
    },
    textField: {
        // marginLeft: theme.spacing.unit,
        // marginRight: theme.spacing.unit,
        // width: 250,
    },
    button: {
        // marginLeft: theme.spacing.unit,
        // marginRight: theme.spacing.unit,
        // marginTop: theme.spacing.unit,
        // width: 250,
    },
    container: {
        position: "relative",
        width: '100%',
        height: 66,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        background: `#fff`,
        margin: '2px 0',
        cursor: "pointer",
    },
    statusIcon: {
      verticalAlign: "middle",
      fontSize: ".8rem",
      marginRight: theme.spacing(0.5)
    },
    caption: {
      color: theme.myColor.grey,
      lineHeight: 1
    },
    deviceInfoTable:{
        "& tr":{
            "& td":{
                borderBottom:0,
            },
            "& td:first-child":{
                color:"rgba(0,0,0,.4)",
            }
        },
    },
    redirectTable:{
        "& tr":{
            "& td":{
                borderBottom:0,
                fontSize:"0.8rem",
            },
        },
    },
    urlInput:{
        fontSize:"0.8rem",
    }
}));

function MyPearBox(props){
    const {onRequire, pearboxDetail, nickname} = props;
    const classes = useStyles();
    const loadding = false;
    const [editNicknameOpen, setEditNicknameOpen] = useState(false);
    const [nicknameValue, setNicknameValue] = useState(nickname||"")
    useEffect(()=>{
        if ( isNaN(parseInt(props.match.params.id)) ){
            window.location.href="/pearbox/overview"
        }
        onRequire(props.match.params.id)
    },[])

    const handleEditNickname = (event)=>{
        setEditNicknameOpen(false)
        if (nicknameValue !== nickname){
            props.updatePearboxNickname(props.match.params.id,event.target.value)   
        }
    }

    const handleNicknameChange = (e) =>{
        setNicknameValue(e.target.value)
    }


    return (
        <Grid container justifyContent="center" spacing={1}>
            <Grid item xs={12} sm={10} md={9} lg={7} xl={6}>
                <Link to={"/kuiconnect/overview"}><ArrowBackIosIcon color="primary" /></Link>
                <br />
                <Paper elevation={3} >
                    <Box m={1} padding={2}>
                        {editNicknameOpen ? <TextField color="primary" autoFocus onBlur={handleEditNickname} value = {nicknameValue} onChange={handleNicknameChange}/>:<Typography variant="h5" onClick={()=>{setEditNicknameOpen(true)}} >{nicknameValue || "未命名"}<CreateIcon fontSize="small" /></Typography>}
                        <Typography  variant="subtitle2">设备ID: {pearboxDetail.node_id}</Typography>
                        {false ? <TextField color="primary" autoFocus onBlur={handleEditNickname} value = {nicknameValue} onChange={handleNicknameChange}/>:<Typography variant="subtitle2" onClick={()=>{setEditNicknameOpen(true)}} >备注：{nicknameValue || "未命名"}<CreateIcon fontSize="small" /></Typography>}
                    </Box>
                </Paper>
                <br />
                <Paper elevation={3} >
                    <Box m={1} padding={2}>
                        <Typography variant="h6" gutterBottom>高级设置</Typography> {/* 可以参照 腾讯云 将此处换成Tab */}
                        <Title>管理路由</Title>
                        <Title className={`mt20`}>ipv4分配</Title>
                    </Box>
                </Paper>
                <Paper elevation={3} >
                    <Box m={1} padding={2}>
                        <Typography variant="h6" gutterBottom>成员</Typography> {/* 可以参照 腾讯云 将此处换成Tab */}
                        <Title>设备</Title>
                        <DeviceTable {...props}/>
                    </Box>
                </Paper>
                
            </Grid>
        </Grid>
    )
}



const mapStateToProps = (state) => {
    return {
        nickname: state.pearbox.nickname,
        deviceID: state.pearbox.deviceID,
        id: state.pearbox.id,
        pearboxDetail: state.pearbox.pearboxDetail || {},
        detailVersion: state.pearbox.detailVersion,
        patchPearboxURLStatus: state.pearbox.patchPearboxURLStatus,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onRequire:(pearboxID)=>{
            dispatch(getPearboxDetail(pearboxID,{
                success:()=>{},
                error:(err)=>{
                    dispatch(messageError(err.err_info))
                }
            }))
        },
        updatePearboxNickname: (pearboxID,nickname)=>{
            dispatch(setPearboxNickname(nickname))
            dispatch(updatePearboxNickname(pearboxID,nickname))
        },
        patchPearboxURL: (data,changeURL)=>{
            dispatch(patchPearboxURL(data,{
                success:(info)=>{
                    dispatch(messageOk("修改成功！"))
                    dispatch(setPearboxDetailURLS(changeURL))
                },
                error:(err)=>{
                    dispatch(messageError(err.err_info))
                }
            }))
        },
        movePearboxURL: (data,changeURL)=>{
            dispatch(patchPearboxURL(data,{
                success:(info)=>{
                    dispatch(messageOk("修改成功！"))
                    dispatch(setPearboxDetailURLS(changeURL))
                },
                error:(err)=>{
                    dispatch(messageError(err.err_info))
                }
            }))
        },
        postPearboxURL: (data,successFunc)=>{
            dispatch(postPearboxURL(data,{
                success:()=>{
                    dispatch(messageOk("修改成功！"))
                    dispatch(getPearboxDetail(data.pearbox_id,{
                        success:()=>{},
                        error:(err)=>{
                            dispatch(messageError(err.err_info))
                        }
                    }))
                    if (successFunc instanceof Function){
                        successFunc()
                    }
                }
            }))

        },
        deletePearboxURL: (data,changeURL)=>{
            dispatch(deletePearboxURL(data,{
                success:()=>{
                    dispatch(messageOk("修改成功！"))
                    dispatch(setPearboxDetailURLS(changeURL))
                }
            }))
        },
        messageError: (data)=>{
            dispatch(messageError(data))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MyPearBox)