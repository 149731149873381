/*
 * @Author: Tperam
 * @Date: 2022-01-18 11:41:27
 * @LastEditTime: 2022-02-24 16:25:31
 * @LastEditors: Tperam
 * @Description: 
 * @FilePath: \pear.link\src\modules\pearbox_overview\actions.js
 */
import xhr from '../../Xhr';
import {tcXhr} from '../../Xhr';

const API = (param={}) => ({
    pearbox: {
        getMyPearbox: {
            url: process.env.REACT_APP_API_PEARBOX + `/pearboxs`,
            method: 'GET'
        },
        bindMyPearbox: {
            url: process.env.REACT_APP_API_PEARBOX + `/pearbox`,
            method: 'POST'
        },
    }
});

// 探测PearBox
export const probePearbox = (ip,event) => {
    return tcXhr({
        api: {
            url: `http://${ip}:8080/system/deviceid`,
            method:'GET',
        },
        timeout: 1000,
        action: 'PEARBOX/OVERVIEW/PROBE/PEARBOX',
        event
    })
};
export const setPearboxIP = (ip) => {
    return {
        type: 'PEARBOX/OVERVIEW/PEARBOX/IP',
        result: ip,
    }
};


export const getMyPearbox = (event) => {
    return xhr({
        api: API().pearbox.getMyPearbox,
        action: 'PEARBOX/OVEERVIEW/MY/PEARBOX',
        event
    })
};
export const bindMyPearbox = (node_id,event) => {
    let data = {
        node_id: node_id,
    }
    return xhr({
        api: API().pearbox.bindMyPearbox,
        action: 'PEARBOX/OVEERVIEW/BIND/MY/PEARBOX',
        data,
        event
    })
};