/*
 * @Author: Tperam
 * @Date: 2022-01-18 11:41:27
 * @LastEditTime: 2022-02-23 10:43:54
 * @LastEditors: Tperam
 * @Description: 
 * @FilePath: \pear.link\src\modules\pearbox\reducer.js
 */
import moment from 'moment'

export const Status = {
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

const checkTime = () => (
  ((moment().unix() - localStorage.getItem('isms_timestamp')) < 6 * 3600 ) ? true : false
)

export default (state = {isAuthenticated: checkTime(),detailVersion:1}, action) => {
  switch(action.type) {
    case 'PEARBOX/NICKNAME':{
      return {...state, nickname: action.result}
    }
    case 'PEARBOX/ID':{
      return {...state, id: action.result}
    }
    case 'PEARBOX/DEVICEID':{
      return {...state, deviceID:action.result}
    }
    case 'PEARBOX/PATCH/NICKNAME_STARTED':{
      return {...state, patchPearboxStatus:Status.LOADING}
    }
    case 'PEARBOX/PATCH/NICKNAME_SUCCESS':{
      return {...state, patchPearboxStatus:Status.SUCCESS}
    }
    case 'PEARBOX/PATCH/NICKNAME_FAILURE':{
      return {...state, patchPearboxStatus:Status.FAILURE}
    }
    case 'PEARBOX/SET/DETAIL/URLS':{
      return {...state, getPearboxDetailStatus:Status.SUCCESS,pearboxDetail:{...state.pearboxDetail,urls:action.result} ,detailVersion: state.detailVersion+1}
    }
    case 'PEARBOX/DETAIL_STARTED':{
      return {...state, getPearboxDetailStatus:Status.LOADING,pearboxDetail:{},nickname:""}
    }
    case 'PEARBOX/DETAIL_SUCCESS':{
      return {...state, getPearboxDetailStatus:Status.SUCCESS,pearboxDetail:action.result,nickname:action.result.nickname,detailVersion: state.detailVersion+1}
    }
    case 'PEARBOX/DETAIL_FAILURE':{
      return {...state, getPearboxDetailStatus:Status.FAILURE}
    }
    case 'PEARBOX/PATCH/URL_STARTED':{
      return {...state, patchPearboxURLStatus:Status.LOADING}
    }
    case 'PEARBOX/PATCH/URL_SUCCESS':{
      return {...state, patchPearboxURLStatus:Status.SUCCESS}
    }
    case 'PEARBOX/PATCH/URL_FAILURE':{
      return {...state, patchPearboxURLStatus:Status.FAILURE}
    }
    case 'PEARBOX/DELETE/URL_STARTED':{
      return {...state, deletePearboxURLStatus:Status.LOADING}
    }
    case 'PEARBOX/DELETE/URL_SUCCESS':{
      return {...state, deletePearboxURLStatus:Status.SUCCESS}
    }
    case 'PEARBOX/DELETE/URL_FAILURE':{
      return {...state, deletePearboxURLStatus:Status.FAILURE}
    }
    default: {
      return state
    }
  }
}
