import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
// import { login } from '../actions';
import Typography from '@material-ui/core/Typography';
import { withStyles,makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import {registeredListSubdomain, fetchInfo, checkingSubdomain, recommendSubdomain, getPearboxDomain, deletePearboxDomain, postPearboxDomain} from './actions'


import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import {messageError, messageOk, setTitle} from "../utils/actions";

import { List, ListItem, Tooltip } from '@material-ui/core';
import { Status } from './reducer';
import Chip from '@material-ui/core/Chip';
const useStyles = makeStyles((theme)=>({
    root: {
        marginTop: theme.spacing.unit * 2,
        overflowX: 'auto',
    },
    main: {
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 650,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    title: {
        marginBottom: theme.spacing.unit * 2,
    },
    subTitle: {
        marginTop: theme.spacing.unit * 2,
    },
    textField: {
        // marginLeft: theme.spacing.unit,
        // marginRight: theme.spacing.unit,
        // width: 250,
    },
    button: {
        // marginLeft: theme.spacing.unit,
        // marginRight: theme.spacing.unit,
        // marginTop: theme.spacing.unit,
        // width: 250,
    },
    chips:{
        color:"#000",
        fontSize:13,
        fontWeight:600,
        fontFamily:'Microsoft YaHei',
    },
    unregisteredChip:{
        color:"#fff",
        backgroundColor:'#63ba4d',
    },
    
}));

let flag = false;
let data = {
    "subdomain":"",
    "domain_id":1,
    "limit":5,
};
let searchDomain = "";
function Domain (props){
    const {registeredSubdomainList,recommendList} = props;
    const classes = useStyles()
    const [subdomain, setSubdomain] = useState("")
    const [domainID, setDomainID] = useState(1)
    const [showList, setShowList] = useState([])

    useEffect(()=>{
        if ( isNaN(parseInt(props.match.params.id)) ){
            window.location.href="/pearbox/overview"
        }

        props.setTitle("Domain");  
    
        const timeInterval = setInterval(()=>{
            if (flag){
                // 发出请求，更新subdomain
                props.registeredListSubdomain(data.domain_id,data.subdomain)
                props.recommendSubdomain(data.domain_id,data.subdomain)
                // 重置
                flag = false
                searchDomain = data.subdomain;
            }
        },3000)
        return ()=>{
            clearInterval(timeInterval)
        }
    },[])

    useEffect( ()=>{
        
        const registeredList = registeredSubdomainList.sort((a,b)=>{
            if (a.subdomain === b.subdomain){
                return 1
            }
            return -1
        }).map((v)=>{
            return <ListItem button divider key={v.subdomain}>
                <Typography variant="subtitle2">
                        {v.subdomain+".pear.link"} <Chip label="已注册" size="small" className={`${classes.chips}`}/>
                </Typography>
            </ListItem>
        })
        const unregisteredList = recommendList.map((v)=>{
            const handleUnregist = ()=>{
                setSubdomain(v)
            }
            return <ListItem button divider key={v} onClick={handleUnregist}>
                    <Typography variant="subtitle2">
                            {v+".pear.link"} <Chip label="未注册" size="small" className={`${classes.unregisteredChip} ${classes.chips}`} />
                    </Typography>
            </ListItem>
        })
        
        switch (true){
            case registeredList.length >= 3 && unregisteredList.length >=2 :{
                let tmp = [];
                tmp.push(unregisteredList[0],unregisteredList[1],registeredList[0],registeredList[1],registeredList[2])
                setShowList(tmp)
                console.log("命中 ", tmp)
                break;
            }
            case registeredList.length < 3 :{
                let tmp = [];
                for (let i=0;i<5-registeredList.length; i ++){
                    tmp.push(unregisteredList[i])    
                }
                for (let i=0;i<registeredList.length; i ++){
                    tmp.push(registeredList[i])    
                }
                console.log("命中 registeredList.length < 3 ", tmp)
                setShowList(tmp)
                break;
            }
            case unregisteredList.length < 2 :{
                let tmp = [];
                for (let i=0;i<unregisteredList.length; i ++){
                    tmp.push(unregisteredList[i])    
                }
                for (let i=0;i<5-unregisteredList.length; i ++){
                    tmp.push(registeredList[i])    
                }
                setShowList(tmp)
                console.log("命中 unregisteredList.length < 2 ", tmp)
                break;
            }
        }
    },[registeredSubdomainList,recommendList])
    

    const handleSubdomainChange = (e) =>{
        if (e.target.value.length >= 2 && e.target.value !== searchDomain){
            data.subdomain = e.target.value
            flag = true
        } else {
            flag = false
            data.subdomain = ""
        }
        setSubdomain(e.target.value)
    }
    const handleSubmit = ()=>{
        const pearboxID = parseInt(props.match.params.id)
        props.postPearboxDomain(domainID,pearboxID,subdomain)
    }

    const handleDomainNumChange = (e) =>{
        data.domain_id=e.target.value
        setDomainID(e.target.value)
    }


    const handleCheck =()=>{
        props.checkingSubdomain(domainID,subdomain)
    }
    
    const submitLoading =  props.getDomainCheckingStatus===Status.LOADING || props.postPearboxDomain===Status.LOADING;

    return <div>
        <Grid container spacing={1} className={classes.main}>
            <Grid item xs={7}>
                <TextField
                    label="主机名称"
                    name="ddns_subdomain"
                    margin= "dense"
                    type= "text"
                    onChange={handleSubdomainChange}
                    variant= "outlined"
                    className={classes.textField}
                    value={subdomain}
                    fullWidth
                />
            </Grid>
            <Grid item xs={3}>
                <FormControl variant="outlined" className={classes.formControl} fullWidth margin="dense">
                    <InputLabel htmlFor="top_domain">顶级域名</InputLabel>
                    <Select
                        value={domainID}
                        name="ddns_domain"
                        onChange={handleDomainNumChange}
                    >
                        <MenuItem value="1">.pear.link</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={2}>
                <Button onClick={handleCheck} variant="contained" color="primary" style={{marginTop:7}} fullWidth disabled={submitLoading}>检查</Button>
            </Grid>
            <Grid item xs={12}>
                <List>
                    {showList}
                </List>
            </Grid>
            <Grid item xs={12} style={{textAlign:"right"}}>
                <Button onClick={handleSubmit} className={classes.button} variant="contained" color="primary" fullWidth disabled={submitLoading}>应用</Button>
            </Grid>
            <Grid item xs={12}>
                <Typography variant='subtitle2' align="right" color="textSecondary">
                    该服务由梨享计算提供
                </Typography>
            </Grid>
        </Grid>
    </div>
}


const mapStateToProps = (state) => {
    return {
        config: state.kuiconnect.config,
        registeredSubdomainList: state.domain.registeredSubdomainList || [],
        recommendList: state.domain.recommendList || [],

        getDomainRegisteredListStatus:state.domain.getDomainRegisteredListStatus,
        getDomainRecommendStatus:state.domain.getDomainRecommendStatus,
        getDomainCheckingStatus:state.domain.getDomainCheckingStatus,
        postPearboxDomain:state.domain.postPearboxDomain,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onRequire: ()=> {
        },
        setTitle: (title) => {
            dispatch(setTitle(title))
        },

        registeredListSubdomain: (domainID,subdomain) => {
            dispatch(registeredListSubdomain(domainID,subdomain,5,{}))
        },
        checkingSubdomain: (domainID,subdomain) => {
            dispatch(checkingSubdomain(domainID,subdomain,{
                success:(info)=>{
                    if (info){
                        dispatch(messageOk("域名可用！"))
                    }else{
                        dispatch(messageError("域名已注册！"))
                    }
                }
            }))
        },
        recommendSubdomain: (domainID,subdomain) => {
            dispatch(recommendSubdomain(domainID,subdomain,{}))
        },
        getPearboxDomain: (pearboxID)=>{
            dispatch(getPearboxDomain(pearboxID,{}))
        }, 
        postPearboxDomain: (domainID,pearboxID,subdomain)=>{
            dispatch(postPearboxDomain(domainID,pearboxID,subdomain,{
                success:()=>{
                    dispatch(messageOk("注册成功！"))
                    setTimeout(()=>{
                        window.location.href="/pearbox/"+pearboxID
                    },500)
                },
                error:()=>{
                    dispatch(messageError("注册失败"))
                }
            }))
        }, 
        deletePearboxDomain: (id,pearboxID)=>{
            dispatch(deletePearboxDomain(id,pearboxID,{}))
        }, 
    };

};

export default connect(mapStateToProps, mapDispatchToProps)(Domain);