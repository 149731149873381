/*
 * @Author: Tperam
 * @Date: 2022-01-18 11:41:27
 * @LastEditTime: 2022-02-28 18:29:59
 * @LastEditors: Tperam
 * @Description: 
 * @FilePath: \pear.link\src\modules\pearbox_overview\container.js
 */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Tab, TextField, useMediaQuery } from '@material-ui/core';
import {Title} from '../utils/title'
import Tabs from '@material-ui/core/Tabs';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import RightArrowIcon from '@material-ui/icons/NavigateNext';
import DotIcon from '@material-ui/icons/FiberManualRecord';

import { makeStyles,useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import IconClose from "@material-ui/icons/Close";
import { bindMyPearbox, getMyPearbox, probePearbox,setPearboxIP } from './actions';
import { setPearboxDeviceID,setPearboxID,setPearboxNickname } from '../pearbox/actions';
import {isInternalIP} from '../utils/ip_verify'
import {messageError, messageOk, setTitle} from '../utils/actions'
import { Link } from 'react-router-dom';
import { Status } from './reducer';


const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing.unit * 2,
        overflowX: 'auto',
    },
    main: {
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    title: {
        marginBottom: theme.spacing.unit * 2,
    },
    subTitle: {
        marginTop: theme.spacing.unit * 2,
    },
    textField: {
        // marginLeft: theme.spacing.unit,
        // marginRight: theme.spacing.unit,
        // width: 250,
    },
    button: {
        // marginLeft: theme.spacing.unit,
        // marginRight: theme.spacing.unit,
        // marginTop: theme.spacing.unit,
        // width: 250,
    },
    container: {
        position: "relative",
        width: '100%',
        height: 66,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        background: `#fff`,
        margin: '2px 0',
        cursor: "pointer",
    },
    statusIcon: {
      verticalAlign: "middle",
      fontSize: ".8rem",
      marginRight: theme.spacing(0.5)
    },
    caption: {
      color: theme.myColor.grey,
      lineHeight: 1
    },
}));

function PearboxOverview(props){
    const {pearboxList,onRequire} = props;
    const classes = useStyles();
    const loadding = false;

    useEffect( ()=>{
        onRequire()
    },[])
    
    const [bindDialog, setBindDialog] = useState(false);

    const handleJumpPearboxItem =(nickname,node_id,id)=>{
        return ()=>{
            props.setPearboxNickname(nickname)
            props.setPearboxDeviceID(node_id)
            props.setPearboxID(id)
        }
    }

    return (
        <Grid container justifyContent="center" spacing={1}>
            <Grid item xs={12} sm={10} md={9} lg={7} xl={6}>
                <Title>我的Pearbox</Title>
                <Paper square variant="fullWidth" indicatorColor="primary" textColor="primary">
                    <Tabs value={0} variant="fullWidth" indicatorColor="primary" textColor="primary">
                        <Tab label={"Pearbox"} />
                    </Tabs>
                </Paper>
                {loadding?<LinearProgress color="primary"/>: null }
                {pearboxList.map((v)=>{
                    return <Link key={v.id} to={`/pearbox/${v.id}`} onClick={handleJumpPearboxItem(v.nickname,v.node_id,v.id)}>
                        <PearboxItem nickname={v.nickname} node_id={v.node_id} id={v.id} />
                    </Link>
                })}
                
                
                <br />
                <br />
                <Title>添加节点</Title>
                <div>
                    <a href={`http://pear.link/bind/pearbox?username=${localStorage.getItem("isms_username")}`}>
                        <AddButton title={"绑定PearBox节点"} subtitle={"将手机或电脑连接到PearBox的局域网，即可自动搜索绑定"}/>
                    </a>
                </div>
                {/* <div onClick={()=>{console.log("点击了")}}>
                    <AddButton title={"绑定远程节点"} subtitle={"PearID，即可绑定远程节点"}/>
                </div> */}
                <AddPearbox {...props} open={bindDialog} onClose={()=>{setBindDialog(false)}}/>
            </Grid>
        </Grid>
    )
}
function PearboxItem(props){
    const {nickname,node_id} = props;
    const classes = useStyles();
    return (
        <Grid className={classes.container} container direction={"row"} justify={"space-between"} alignItems={"center"}>
            <Grid item xs container direction={"column"} justify={"center"} alignItems={"flex-start"}>
                <Grid item xs={12}>
                    <Typography variant="subtitle1">{nickname||"未命名"}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <DotIcon className={`${classes.statusIcon}`}/><Typography variant="caption" className={classes.caption}>{node_id}</Typography>
                </Grid>
            </Grid>
            <Grid item>
                <div style={{display: "inline-block", verticalAlign: "top", textAlign: "right"}}>
                    {/* <Typography display="block" variant="caption" className={classes.caption}> 99 / 100</Typography> */}
                    {/* <Typography display="block" variant="caption" className={classes.caption}>已使用流量 / 总流量流量 </Typography> */}
                </div>
                <RightArrowIcon className={classes.icon} color="action"/>
            </Grid>
        </Grid>
    )
}

function AddButton(props){
    const  {title,subtitle} = props;
    const classes = useStyles();
    return (
        <Grid className={classes.container} container direction={"row"} justify={"space-between"} alignItems={"center"}>
            <Grid item xs container direction={"column"} justify={"center"} alignItems={"flex-start"}>
            <Grid item xs={12}>
                <Typography variant="subtitle1">{title}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="caption" className={classes.caption}>{subtitle}</Typography>
            </Grid>
            </Grid>
            <Grid item>
                <RightArrowIcon className={classes.icon} color="action"/>
            </Grid>
        </Grid>
    )
}

const probeIP = [
    "192.168.88.1",
    "127.0.0.1",
];

function AddPearbox(props){
    const classes = useStyles();
    
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    
    const { onClose, open, pearboxIP,setPearboxIP, bindPearboxInfo,probePearbox,
        messageError,
        probeNum
    } = props;
    
    const handleClose = () => {
        onClose();
    };
    // 绑定操作
    const handleBind = () => {
        if (! isInternalIP(pearboxIP||"")){
            return messageError("请输入正确的IP")
        }
        bindPearboxInfo(pearboxIP,handleClose)
    }
    // 探测操作
    const handleProbe = () =>{
        for (let ip of probeIP){
            probePearbox(ip)
        }
    }
    const handlePearboxIPChange = (event)=>{
        setPearboxIP(event.target.value)
    }
    return (
        <Dialog 
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={open}
            maxWidth={"xs"}
            fullWidth={true}
            fullScreen={fullScreen}
        >
            <DialogTitle id="simple-dialog-title"> 
                <IconButton onClick={handleClose} color="primary"> <ArrowBackIosIcon /></IconButton>
                绑定节点
            </DialogTitle>
            <DialogContent >
                {/* <TextField 
                    label="用户名"
                    value={localStorage.getItem("isms_username")}
                    fullWidth
                    disabled
                />
                <br />
                <br /> */}
                <div style={{"position":"relative"}}>
                    <TextField 
                        label="Pearbox IP"
                        fullWidth
                        value={pearboxIP}
                        onChange={handlePearboxIPChange}
                    />
                    <Button onClick={handleProbe} color="primary" style={{width:"100px",position:"absolute",right:"0px",top:"9px"}} disabled={props.probeNum !== 0} >
                        自动查找
                    </Button>
                </div>
                {/* <br />
                <br />
                <TextField 
                    label="设备 ID"
                    fullWidth
                /> */}
            </DialogContent>
            <br />
            {/* <DialogActions style={{justifyContent: "center"}}>
                
            </DialogActions> */}
            <DialogActions style={{justifyContent: "center"}}>
                <Button onClick={handleBind} color="primary" variant="contained" style={{width:"90%"}} disabled={props.bindMyPearboxStatus === Status.LOADING} >
                    绑定
                </Button>
            </DialogActions>
            <br />
        </Dialog>
    )
}

const mapStateToProps = (state) => {
    return {
        pearboxIP: state.pearboxOverview.pearboxIP || "",
        pearboxList: state.pearboxOverview.pearboxList || [],
        probePearboxStatus: state.pearboxOverview.probePearboxStatus,
        probeNum: state.pearboxOverview.probeNum,
        bindMyPearboxStatus: state.pearboxOverview.bindMyPearboxStatus,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onRequire:()=>{
            dispatch(getMyPearbox())
            dispatch(setTitle("PearBox"))
        },
        setPearboxIP: (data)=>{
            dispatch(setPearboxIP(data))
        },
        messageError: (data)=>{
            dispatch(messageError(data))
        },
        probePearbox: (ip)=>{
            dispatch(probePearbox(ip,{
                success:()=>{
                    dispatch(setPearboxIP(ip))
                }
            }))
        },
        // bindPearbox
        bindPearboxInfo: (ip,successFunc)=>{
            // 获取用户输入的IP
            dispatch(probePearbox(ip,{
                success:(info)=>{
                    
                    dispatch(bindMyPearbox(info["node_id"],{
                        success:()=>{
                            dispatch(messageOk("绑定成功！"))
                            dispatch(getMyPearbox())
                            successFunc()
                        },
                        error: (err)=>{
                            dispatch(messageError(err.err_info))
                        }
                    }))
                },
                error:()=>{
                    dispatch(messageError("PearBox IP错误"))
                }
            }))
        },
        setPearboxNickname: (nickname)=>{
            dispatch(setPearboxNickname(nickname))
        },
        setPearboxID: (id)=>{
            dispatch(setPearboxID(id))
        },
        setPearboxDeviceID: (node_id)=>{
            dispatch(setPearboxDeviceID(node_id))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PearboxOverview)