/*
 * @Author: Tperam
 * @Date: 2022-01-18 11:26:26
 * @LastEditTime: 2022-03-18 18:25:36
 * @LastEditors: Tperam
 * @Description: 
 * @FilePath: \pear.link\src\modules\kuiconnect\reducer.js
 */
import moment from 'moment'

export const Status = {
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

const checkTime = () => (
  ((moment().unix() - localStorage.getItem('isms_timestamp')) < 6 * 3600 ) ? true : false
)

export default (state = {isAuthenticated: checkTime()}, action) => {
  switch(action.type) {
    case 'KUICONNECT/INFO_STARTED': {
      console.log("start");
        return {...state, fetchInfoStatus: Status.LOADING}
    }
    case 'KUICONNECT/INFO_SUCCESS': {
        console.log("success");
        return {...state, fetchInfoStatus: Status.SUCCESS, info: action.result}
    }
    case 'KUICONNECT/INFO_FAILURE': {
        return {...state, fetchInfoStatus: Status.FAILURE, error: action.error}
    }
    default: {
      return state
    }
  }
}
