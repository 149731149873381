import { LaptopWindows } from '@material-ui/icons'
import xhr from '../../Xhr'

const API = (id) => ({
  auth: {
    login: {
      url: process.env.REACT_APP_API_SERVER + `/v1/vdn/login`,
      method: 'POST'
    }
  }
})

export const login = ({data, ...event}) => {
  return xhr({
    api: API(localStorage.getItem('isms_userid')).auth.login,
    action: 'AUTH/LOGIN',
    data,
    event
  })
}

export const logout = () => ({
  type: 'AUTH/LOGOUT',
})

export const loginQuery = (data) => ({
    type: 'AUTH/LOGIN',
    data
})
