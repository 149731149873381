/*
 * @Author: Tperam
 * @Date: 2022-01-18 11:26:26
 * @LastEditTime: 2022-02-18 17:54:41
 * @LastEditors: Tperam
 * @Description: 
 * @FilePath: \pear.link\src\modules\domain\actions.js
 */
import xhr from '../../Xhr';
import {xhrOk} from '../../Xhr';

const API = (param={}) => ({
    ddns: {
        infoFetch: {
            url: process.env.REACT_APP_API_MAGIC + `/v1/magicbox/info`,
            method: 'GET'
        },
        configFetch: {
            url: localStorage.getItem('routerIp') + `:7120/v1/fogconnect/info`,
            method: 'GET'
        },
    },
    subdomain:{
        registeredList:{
            url: process.env.REACT_APP_API_PEARBOX + `/subdomain/registrations?domain_id=${param.domain_id}&subdomain_prefix=${param.subdomain}&limit=${param.limit}`,
            method: "GET"
        },
        checking:{
            url: process.env.REACT_APP_API_PEARBOX + `/subdomain/checking?domain_id=${param.domain_id}&subdomain=${param.subdomain}`,
            method: "GET"
        },
        recommend:{
            url: process.env.REACT_APP_API_PEARBOX + `/subdomain/recommendations?domain_id=${param.domain_id}&subdomain_prefix=${param.subdomain}`,
            method: "GET"
        },
    },
    domain:{
        get:{
            url: process.env.REACT_APP_API_PEARBOX + `/domains`,
            method: "GET"
        },
        getPearboxDomain:{
            url: process.env.REACT_APP_API_PEARBOX + `/pearbox/domains?pearbox_id=${param.pearbox_id}`,
            method: "GET"
        },
        postPearboxDomain: {
            url: process.env.REACT_APP_API_PEARBOX + `/pearbox/domain`,
            method: "POST"
        },
        deletePearboxDomain: {
            url: process.env.REACT_APP_API_PEARBOX + `/pearbox/domain`,
            method: "DELETE"
        }
    }

});

export const fetchInfo = ({...event}) => {
    return xhr({
        api: API().ddns.infoFetch,
        action: 'DDNS/INFO',
        event
    })
};

export const registeredListSubdomain = (domainID,subdomain,limit,event)=>{
    let param = {
        "domain_id":domainID,
        "subdomain":subdomain,
        "limit":limit,
    };
    return xhr({
        api: API(param).subdomain.registeredList,
        action: "DOMAIN/REGISTERED/LIST/SUBDOMAIN",
        event
    })
}
export const checkingSubdomain = (domainID,subdomain,event)=>{
    let param = {
        "domain_id":domainID,
        "subdomain":subdomain,
    }
    return xhr({
        api: API(param).subdomain.checking,
        action: "DOMAIN/CHECKING/SUBDOMAIN",
        event
    })
}
export const recommendSubdomain = (domainID,subdomain,event)=>{
    let param ={
        "domain_id":domainID,
        "subdomain":subdomain,
    }
    return xhr({
        api: API(param).subdomain.recommend,
        action: "DOMAIN/RECOMMEND/SUBDOMAIN",
        event
    })
}
export const getDomain = (event)=>{
    
    return xhr({
        api: API().domain.get,
        action: "DOMAIN/GET/DOMAIN",
        event
    })
}
export const getPearboxDomain = (pearboxID,event)=>{
    let param = {
        "pearbox_id":pearboxID
    }
    return xhr({
        api: API(param).domain.getPearboxDomain,
        action: "DOMAIN/GET/PEARBOX/DOMAIN",
        event
    })
}
export const postPearboxDomain = (domainID,pearboxID,subdomain,event)=>{
    let data = {
        "domain_id":domainID,
        "pearbox_id":pearboxID,
        "subdomain":subdomain,
    }
    return xhr({
        api: API().domain.postPearboxDomain,
        action: "DOMAIN/POST/PEARBOX/DOMAIN",
        data,
        event
    })
}
export const deletePearboxDomain = (id,pearboxID,event)=>{
    let data = {
        "id":id,
        "pearbox_id":pearboxID,
    }
    return xhr({
        api: API().domain.deletePearboxDomain,
        action: "DOMAIN/DELETE/PEARBOX/DOMAIN",
        data,
        event
    })
}