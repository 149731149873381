/*
 * @Author: Tperam
 * @Date: 2022-01-18 11:41:27
 * @LastEditTime: 2022-02-24 17:59:49
 * @LastEditors: Tperam
 * @Description: 
 * @FilePath: \pear.link\src\modules\pearbox\actions.js
 */
import xhr from '../../Xhr';

const API = (param={}) => ({
    pearbox: {
        patchPearbox:{
            url: process.env.REACT_APP_API_PEARBOX + `/pearbox`,
            method: 'PATCH'
        },
        getPearboxDetail:{
            url: process.env.REACT_APP_API_PEARBOX + `/pearbox/details?pearbox_id=${param.pearboxID}`,
            method: 'GET'
        },
        patchPearboxURL:{
            url:process.env.REACT_APP_API_PEARBOX + '/pearbox/urls',
            method: 'PUT'
        },
        postPearboxURL:{
            url:process.env.REACT_APP_API_PEARBOX + '/pearbox/url',
            method: 'POST'
        },
        deletePearboxURL:{
            url:process.env.REACT_APP_API_PEARBOX + '/pearbox/url',
            method: 'DELETE'
        },
    }
});


export const setPearboxNickname = (nickname)=>{
    return {
        type:"PEARBOX/NICKNAME",
        result: nickname,
    }
}
export const setPearboxID = (id) =>{
    return {
        type:"PEARBOX/ID",
        result: id,
    }    
}

// 增加detailVersion
export const setPearboxDetailURLS = (detailUrl) =>{
    return {
        type:"PEARBOX/SET/DETAIL/URLS",
        result: detailUrl,
    }    
}
export const setPearboxDeviceID = (deviceID) =>{
    return {
        type:"PEARBOX/DEVICEID",
        result: deviceID,
    }    
}

export const updatePearboxNickname = (pearboxID,nickname,event) => {
    let data = {
        pearbox_id: parseInt(pearboxID),
        nickname:nickname,
    }
    return xhr({
        api: API().pearbox.patchPearbox,
        action: 'PEARBOX/PATCH/NICKNAME',
        data,
        event
    })
};
export const getPearboxDetail = (pearboxID,event) => {
    return xhr({
        api: API({pearboxID}).pearbox.getPearboxDetail,
        action: 'PEARBOX/DETAIL',
        event
    })
};

export const patchPearboxURL = (data,event)=>{
    return xhr({
        api:API().pearbox.patchPearboxURL,
        action:"PEARBOX/PATRCH/URL",
        data,
        event,
    })
}
export const postPearboxURL = (data,event)=>{
    return xhr({
        api:API().pearbox.postPearboxURL,
        action:"PEARBOX/POST/URL",
        data,
        event,
    })
}
export const deletePearboxURL = (data,event)=>{
    return xhr({
        api:API().pearbox.deletePearboxURL,
        action:"PEARBOX/DELETE/URL",
        data,
        event,
    })
}