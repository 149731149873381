/*
 * @Author: Tperam
 * @Date: 2022-01-18 11:26:26
 * @LastEditTime: 2022-02-28 11:29:37
 * @LastEditors: Tperam
 * @Description: 
 * @FilePath: \pear-link-ing-202201\src\Xhr.js
 */
const initEvent = (event) => ({
  success: () => {},
  error: () => {},
  ...event
})

const initAction = (action, data) => ({
  start: () => ({
    type: action + '_STARTED'
  }),
  success: (result) => ({
    type: action + '_SUCCESS',
    result,
    data
  }),
  failure: (error) => ({
    type: action + '_FAILURE',
    error
  })
})

const xhr = ({api, ...param}) => {
  const data = param.data || undefined
  const event = initEvent(param.event)
  const action = initAction(param.action, param.data)
  return (dispatch) => {
    dispatch(action.start())
    return fetch(api.url, {
      method: api.method,
      headers: new Headers({
        'X-Pear-Token': localStorage.getItem('isms_token')
      }),
      body: JSON.stringify(data)
    })
    .then((response) => {
      response.json().then((responseJson) => {
        switch (response.status) {
          case 200:
            if( responseJson.err_code !==0 && responseJson.err_code !== undefined){
              dispatch(action.failure(responseJson))
              event.error(responseJson)
            } else { 
              dispatch(action.success(responseJson))
              event.success(responseJson)
            }
            break
          default:
            dispatch(action.failure(responseJson))
            event.error(responseJson)
            break
        }
      }).catch((error) => {
        dispatch(action.failure(error))
        event.error(error)
      })
    })
    .catch((error) => {
      dispatch(action.failure(error))
      event.error(error)
    })
  }
}

export const xhrOk = ({api, ...param}) => {
    const data = param.data || undefined
    const event = initEvent(param.event)
    const action = initAction(param.action, param.data)
    return (dispatch) => {
        dispatch(action.start())
        return fetch(api.url, {
            method: api.method,
            // headers: new Headers({
            //     'X-Pear-Token': localStorage.getItem('isms_token')
            // }),
            body: JSON.stringify(data)
        })
            .then((response) => {
                switch (response.status) {
                    case 200:
                        break
                    default:
                        dispatch(action.failure())
                        event.error()
                        break
                }
            })
            .catch((error) => {
                dispatch(action.failure(error))
                event.error(error)
            })
    }
}

// timeout control xhr
export const tcXhr = ({api,timeout, ...param})=>{
  const data = param.data || undefined
  const event = initEvent(param.event)
  const action = initAction(param.action, param.data)


  let controller = new AbortController();
  let signal = controller.signal;
  // 超时函数
  const timeoutAction = timer => {
    return new Promise(reslove => {
      setTimeout(() => {
        // 实例化超时响应json数据
        const response = new Response(
          JSON.stringify({
            code: -1,
            errMsg: `timeout ${timer}s`
          }),
          {
            status:408
          }
        );
        reslove(response);
        controller.abort(); // 发送终止信号
      }, timer);
    });
  };

  // 执行，超时控制在0秒，让它永远超时，方便观察
  return (dispatch) =>{
    dispatch(action.start())
    return Promise.race([
      timeoutAction(timeout),
      fetch(api.url, {
        signal: signal,
        method: api.method,
        headers: new Headers({
          'X-Pear-Token': localStorage.getItem('isms_token')
        }),
        body: JSON.stringify(data)
      })
    ])
      .then(response => {
        response.json().then((responseJson) => {
          switch (response.status) {
            case 200:
              if( responseJson.err_code !==0 && responseJson.err_code !== undefined){
                dispatch(action.failure(responseJson))
                event.error(responseJson)
              } else { 
                dispatch(action.success(responseJson))
                event.success(responseJson)
              }
              break
            default:
              dispatch(action.failure(responseJson))
              event.error(responseJson)
              break
          }
        }).catch((error) => {
          dispatch(action.failure(error))
          event.error(error)
        })
      })
      .catch((error) => {
          dispatch(action.failure(error))
          event.error(error)
      })
  }
}

export default xhr
