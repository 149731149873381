/*
 * @Author: Tperam
 * @Date: 2022-01-18 11:41:27
 * @LastEditTime: 2022-02-07 16:36:03
 * @LastEditors: Tperam
 * @Description: 
 * @FilePath: \pear.link\src\modules\pearbox_overview\reducer.js
 */
import moment from 'moment'

export const Status = {
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

const checkTime = () => (
  ((moment().unix() - localStorage.getItem('isms_timestamp')) < 6 * 3600 ) ? true : false
)

export default (state = {isAuthenticated: checkTime(),probeNum:0}, action) => {
  switch(action.type) {
    case 'PEARBOX/OVERVIEW/PROBE/PEARBOX_STARTED':{
      return {...state, probePearboxStatus: Status.LOADING, probeNum:state.probeNum+1}
    }
    case 'PEARBOX/OVERVIEW/PROBE/PEARBOX_SUCCESS':{
      return {...state, probePearboxStatus: Status.SUCCESS, bindPearboxInfo: action.result, probeNum:state.probeNum-1}
    }
    case 'PEARBOX/OVERVIEW/PROBE/PEARBOX_FAILURE':{
      return {...state, probePearboxStatus: Status.FAILURE, probeNum:state.probeNum-1}
    }
    case 'PEARBOX/OVERVIEW/PEARBOX/IP':{
      return {...state, pearboxIP: action.result}
    }
    case 'PEARBOX/OVEERVIEW/MY/PEARBOX_STARTED':{
      return {...state, getMyPearboxStatus: Status.LOADING}
    }
    case 'PEARBOX/OVEERVIEW/MY/PEARBOX_SUCCESS':{
      return {...state, getMyPearboxStatus: Status.SUCCESS, pearboxList: action.result}
    }
    case 'PEARBOX/OVEERVIEW/MY/PEARBOX_FAILURE':{
      return {...state, getMyPearboxStatus: Status.FAILURE}
    }
    case 'PEARBOX/OVEERVIEW/BIND/MY/PEARBOX_STARTED':{
      return {...state, bindMyPearboxStatus: Status.LOADING}
    }
    case 'PEARBOX/OVEERVIEW/BIND/MY/PEARBOX_SUCCESS':{
      return {...state, bindMyPearboxStatus: Status.SUCCESS}
    }
    case 'PEARBOX/OVEERVIEW/BIND/MY/PEARBOX_FAILURE':{
      return {...state, bindMyPearboxStatus: Status.FAILURE}
    }
    default: {
      return state
    }
  }
}
