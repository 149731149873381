import moment from 'moment'

const Status = {
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

const checkTime = () => (
  ((moment().unix() - localStorage.getItem('isms_timestamp')) < 6 * 3600 ) ? true : false
)

export default (state = {isAuthenticated: checkTime()}, action) => {
    const setStorage = (result) => {
        localStorage.setItem('isms_token', result.token)
        localStorage.setItem('isms_userid', result.user_id)
        localStorage.setItem('isms_username', result.username)
        localStorage.setItem('isms_timestamp', moment().unix())
        localStorage.setItem('routerIp', result.ip)
    }
  switch(action.type) {
    case 'AUTH/LOGIN_STARTED': {
      return {...state, loginStatus: Status.LOADING}
    }
    case 'AUTH/LOGIN_SUCCESS': {
      localStorage.setItem('isms_token', action.result.token);
      localStorage.setItem('isms_userid', action.result.user_id);
      localStorage.setItem('isms_username', action.result.username);
      localStorage.setItem('isms_timestamp', moment().unix());
      localStorage.setItem('timeServer', "time.nist.gov");
      return {...state, loginStatus: Status.SUCCESS, isAuthenticated: true}
    }
    case 'AUTH/LOGIN_FAILURE': {
      return {...state, loginStatus: Status.FAILURE, error: action.error}
    }
      case 'AUTH/LOGIN': {
          setStorage(action.data)
          return {...state, isAuthenticated: true}
      }
    case 'AUTH/LOGOUT': {
      localStorage.clear()
      return {...state, isAuthenticated: false}
    }
    default: {
      return state
    }
  }
}
