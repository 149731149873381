/*
 * @Author: Tperam
 * @Date: 2022-01-18 11:26:26
 * @LastEditTime: 2022-03-21 11:53:51
 * @LastEditors: Tperam
 * @Description: 
 * @FilePath: \pear.link\src\Store.js
 */
import {createStore, combineReducers, applyMiddleware, compose} from 'redux'
import thunkMiddleware from 'redux-thunk'
import { reducer as Auth } from './modules/auth/'
import { reducer as Utils } from './modules/utils/'
import { reducer as Domain } from './modules/domain'
import { reducer as Kuiconnect } from './modules/kuiconnect'
import { reducer as KuiconnectOverView } from './modules/kuiconnect_overview'
import { reducer as PearBox } from './modules/pearbox'
import { reducer as PearBoxOverview } from './modules/pearbox_overview'

const reducer = combineReducers({
  auth: Auth,
  utils: Utils,
  domain: Domain,
  kuiconnect:Kuiconnect,
  kuiconnecOverview:KuiconnectOverView,
  pearbox: PearBox,
  pearboxOverview: PearBoxOverview,
})

const middlewares = [thunkMiddleware]

const storeEnhancers = compose(
  applyMiddleware(...middlewares),
  (window && window.devToolsExtension) ? window.devToolsExtension() : (f) => f,
)

export default createStore(reducer, {}, storeEnhancers)
