/*
 * @Author: Tperam
 * @Date: 2022-01-26 14:49:48
 * @LastEditTime: 2022-01-26 15:13:56
 * @LastEditors: Tperam
 * @Description: 
 * @FilePath: \pear.link\src\modules\utils\ip_verify.js
 */

const IPPattern = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/ 


export const isValidIP  = (ip)=>{
    return IPPattern.test(ip)
}


export const isInternalIP = (ip) =>{
    if (isValidIP(ip)){
        let tmp = ip.split(".")
        switch (tmp[0]){
        case '192':
            return tmp[1]=="168"
        case '10':
            return true
        case '172':
            return tmp[1] <= 31 && tmp[1] >=16 
        case "127":
            return tmp[1] == 0 && tmp[2] == 0 && tmp[3] == 1
        }
    }
    return false
}